/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.opaqueBorderAfter::after {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  content: " ";
  height: 15px;
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 0; }

@media (min-width: 768px) {
  .opaqueBorderAfter::after {
    height: 57px; } }

.opaqueBorderBefore::before {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  content: " ";
  height: 15px;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0; }

@media (min-width: 768px) {
  .opaqueBorderBefore::before {
    height: 57px; } }

.visibilityHidden, .formWrap label {
  clip: rect(0 0 0 0);
  position: absolute;
  overflow: hidden;
  margin: -1px;
  height: 1px;
  padding: 0;
  width: 1px;
  border: 0; }

.bgGradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,070707+100&0.75+0,0.75+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(238, 238, 238, 0.75) 0%, rgba(7, 7, 7, 0.75) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfeeeeee', endColorstr='#bf070707',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }

@font-face {
  font-family: "Carnas";
  src: url("../font/carnas/carnas-lig-webfont.eot");
  src: url("../font/carnas/carnas-lig-webfont.eot?#iefix") format("embedded-opentype"), url("../font/carnas/carnas-lig-webfont.woff") format("woff"), url("../font/carnas/carnas-lig-webfont.ttf") format("truetype"), url("../font/carnas/carnas-lig-webfont.svg#Carnas") format("svg");
  font-weight: normal;
  font-style: normal; }

h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin-top: 0; }

h1 {
  font-size: 32px; }

h2 {
  font-size: 30px; }

h3 {
  font-size: 27px; }

h4 {
  font-size: 18px; }
  h4.product_title {
    margin-bottom: 10px; }

h5 {
  font-size: 17px; }

.store__page_header {
  display: inline-block; }

.store__page_header,
.store__page_sub_header {
  background: #F2F2F2;
  text-align: center;
  padding: 3px;
  width: 100%;
  margin: 0; }

.store__page_header_bottom {
  text-align: center;
  margin: 0 auto; }

.store__page_sub_header {
  padding: 7px 0; }

.store__page_header_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.store__page_header {
  display: inline-block;
  padding-top: 2px;
  width: 100%; }

@media (min-width: 768px) {
  .store__page_header {
    padding: 5px 0; } }

@media (min-width: 1024px) {
  .store__page_sub_header {
    padding: 13px 0 12px; }
  .store__page_header {
    width: 100%; } }

p {
  font-size: 16px;
  margin-top: 0; }
  p.important-notice {
    padding: 10px 10px 15px;
    text-align: center;
    background: #000;
    color: #fff;
    margin: 0; }

span.vat, .author, .form_small, .important-notice, .menu__header__account {
  font-size: 14px; }

@media (min-width: 768px) {
  h1 {
    font-size: 54px; }
  h2 {
    font-size: 40px; }
  h3 {
    font-size: 34px; }
  h4 {
    font-size: 22px; }
  h5 {
    font-size: 20px; }
  p {
    font-size: 16px; } }

button, a.button {
  -webkit-backface-visibility: hidden;
  -webkit-transition: background-color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out;
  background-color: #DCDCDC;
  border-radius: 1px;
  margin: 0 0 20px;
  display: block;
  padding: 10px;
  color: #000;
  border: none;
  height: 43px;
  width: 100%;
  text-decoration: none; }
  button:hover, a.button:hover {
    background-color: #c3c3c3;
    cursor: pointer; }
  @media (min-width: 768px) {
    button, a.button {
      max-width: 400px;
      margin: 0 auto; } }
  button[disabled], button:disabled, a.button[disabled], a.button:disabled {
    background: #F2F2F2;
    cursor: not-allowed;
    color: #B3B3B3; }
  button.quiz_button, a.button.quiz_button {
    margin-bottom: 20px;
    line-height: initial;
    text-transform: uppercase; }

.buttonWrapper button {
  height: 60px; }

button.dark, .button.dark {
  background-color: #000;
  color: #fff; }
  button.dark:hover, .button.dark:hover {
    background-color: #4d4d4d; }

button.bright {
  background-color: #FEDE94;
  color: #000; }
  button.bright:hover {
    background-color: #fece61; }

button.green {
  background-color: #63C997;
  margin-bottom: 5px;
  color: #fff; }
  button.green span {
    background: url("../../../assets/images/icons/buyButtonIcon.svg") no-repeat center right;
    padding: 10px 40px 10px 0; }
  button.green:hover {
    background-color: #40b97e; }

button.quote {
  margin-bottom: 0; }
  button.quote span {
    background: url("../../../assets/images/icons/addToQuote.svg") no-repeat center right;
    padding: 10px 40px 10px 0; }

input, select {
  text-transform: capitalize;
  -webkit-appearance: none;
  border: 1px solid #DCDCDC;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto 5px;
  font-size: inherit;
  border-radius: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-height: 40px;
  padding: 10px;
  width: 100%; }

input[type='checkbox'] {
  -webkit-appearance: checkbox;
  width: 20px;
  height: 20px; }

input[type='email'] {
  text-transform: none; }

.formWrap.customTextBox {
  display: inline-block;
  margin-bottom: 0;
  /* checkbox aspect */
  /* disabled checkbox */ }
  .formWrap.customTextBox .custom_checkbox {
    display: inline-block;
    margin: 15px 15px 0; }
  .formWrap.customTextBox [type="checkbox"]:not(:checked),
  .formWrap.customTextBox [type="checkbox"]:checked {
    position: absolute;
    left: -9999px; }
  .formWrap.customTextBox [type="checkbox"]:not(:checked) + label,
  .formWrap.customTextBox [type="checkbox"]:checked + label {
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    position: relative;
    padding-left: 30px;
    overflow: visible;
    text-align: left;
    cursor: pointer;
    height: 25px;
    width: auto;
    clip: unset;
    margin: 0; }
  .formWrap.customTextBox [type="checkbox"]:checked + label {
    opacity: 0.5; }
  .formWrap.customTextBox [type="checkbox"]:not(:checked) + label:before,
  .formWrap.customTextBox [type="checkbox"]:checked + label:before {
    background: url("../../../assets/images/icons/page-link-plus-black-icon.svg") no-repeat center center;
    background-size: 20px 23px;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    /* IE 9 */
    -webkit-transform-origin: 50% 50%;
    /* Chrome, Safari, Opera */
    transform-origin: 50% 50%;
    position: absolute;
    height: 25px;
    width: 25px;
    content: '';
    opacity: 1;
    left: 0;
    top: -2px; }
  .formWrap.customTextBox [type="checkbox"]:not(:checked) + label:before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  .formWrap.customTextBox [type="checkbox"]:checked + label:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0.5; }
  .formWrap.customTextBox [type="checkbox"]:disabled:not(:checked) + label:before {
    opacity: 0.5; }
  .formWrap.customTextBox [type="checkbox"]:disabled:checked + label:before {
    opacity: 0.5; }
  .formWrap.customTextBox [type="checkbox"]:disabled + label {
    color: #aaa; }

textarea {
  text-transform: none;
  border: 1px solid #DCDCDC;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 5px 10px 10px;
  margin: 0 auto 20px;
  font-size: inherit;
  border-radius: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-height: 40px;
  width: 100%; }

label {
  font-size: inherit; }

.formWrap {
  margin: 0 auto 10px; }
  .formWrap label {
    margin: 0 0 5px 0;
    text-align: left;
    cursor: pointer;
    display: block; }
  .formWrap .unhideLabel {
    margin: 0 0 10px; }
    .formWrap .unhideLabel input, .formWrap .unhideLabel label {
      vertical-align: top;
      min-height: 0;
      height: 20px; }
    .formWrap .unhideLabel input[type='checkbox'] {
      display: inline-block; }
    .formWrap .unhideLabel label {
      display: inline-block;
      position: relative;
      clip: initial;
      height: auto;
      width: 90%; }
  @media (min-width: 1024px) {
    .formWrap.inline input, .formWrap.inline label {
      display: inline-block;
      width: auto; }
    .formWrap.inline input {
      margin: 0 0 20px 10px; } }

.form_small {
  text-align: center;
  margin: 0 0 20px;
  display: block; }
  .form_small.right {
    text-align: right; }

.form_help_text {
  margin-top: 15px; }

.subscription__form input {
  margin-bottom: 0; }

select {
  background: url("../../../assets/images/icons/selectDownArrow.svg") #fff no-repeat 95% center;
  background-size: 27px 14px;
  position: relative; }

address.complete {
  font-style: normal; }

.subscription__list {
  display: none; }

.subscription__list__item {
  display: inline-block;
  width: auto; }
  .subscription__list__item .mask_image_wrapper {
    height: auto; }

@media (min-width: 1024px) {
  .subscription__list {
    display: inline-block;
    width: 430px; }
  .subscription__list__item {
    margin-right: 8px;
    height: 135px;
    width: 135px; }
    .subscription__list__item:last-child {
      margin-right: 0; }
    .subscription__list__item img {
      height: 100%;
      width: 100%; } }

@media (min-width: 1280px) {
  .subscription__list {
    width: 535px; }
  .subscription__list__item {
    margin-right: 15px;
    height: 165px;
    width: 165px; } }

.hex_colours_list {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-align: center;
  max-width: 320px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .hex_colours_list .hex_colours_list_item {
    -webkit-backface-visibility: hidden;
    -webkit-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0 20px 20px 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 155px;
    width: 138px;
    float: left; }
    .hex_colours_list .hex_colours_list_item:nth-child(even) {
      margin-right: 0; }
    .hex_colours_list .hex_colours_list_item .hex_colours_list_link {
      text-decoration: none;
      color: #fff; }
    .hex_colours_list .hex_colours_list_item:hover {
      opacity: 0.7; }
      .hex_colours_list .hex_colours_list_item:hover .hex_colours_list_title {
        color: #b3b3b3; }
  .question-3 .hex_colours_list .hex_colours_list_item {
    margin: 0 6px 0 0;
    height: 102px;
    width: 90px; }
    .question-3 .hex_colours_list .hex_colours_list_item:last-child {
      margin-right: 0; }
    .question-3 .hex_colours_list .hex_colours_list_item .hex_colours_list_image {
      height: 102px;
      width: 90px; }
  .hex_colours_list .hex_colours_list_image {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0; }
  .hex_colours_list .hex_colours_list_title {
    -webkit-backface-visibility: hidden;
    -webkit-transition: color 200ms ease-in-out;
    transition: color 200ms ease-in-out;
    text-align: center;
    padding: 0 5px;
    color: #fff;
    width: 100%;
    z-index: 1;
    margin: 0; }
  @media (min-width: 768px) {
    .hex_colours_list {
      text-align: center;
      margin: 30px 0 0;
      max-width: none; }
      .hex_colours_list .hex_colours_list_item,
      .hex_colours_list .hex_colours_list_item:nth-child(even) {
        margin: 0 40px 20px 0;
        height: 175px;
        width: 156px; }
        .hex_colours_list .hex_colours_list_item:last-child,
        .hex_colours_list .hex_colours_list_item:nth-child(even):last-child {
          margin-right: 0; }
        .hex_colours_list .hex_colours_list_item .hex_colours_list_image,
        .hex_colours_list .hex_colours_list_item:nth-child(even) .hex_colours_list_image {
          height: 175px;
          width: 156px; }
      .question-3 .hex_colours_list .hex_colours_list_item {
        margin: 0 25px 20px 0;
        height: 141px;
        width: 126px; }
        .question-3 .hex_colours_list .hex_colours_list_item:last-child {
          margin-right: 0; }
        .question-3 .hex_colours_list .hex_colours_list_item .hex_colours_list_image {
          height: 141px;
          width: 126px; } }
  @media (min-width: 1024px) {
    .hex_colours_list .hex_colours_list_item,
    .hex_colours_list .hex_colours_list_item:nth-child(even) {
      margin: 0 100px 20px 0; }
      .hex_colours_list .hex_colours_list_item:last-child,
      .hex_colours_list .hex_colours_list_item:nth-child(even):last-child {
        margin-right: 0; }
    .question-3 .hex_colours_list .hex_colours_list_item {
      margin: 0 50px 20px 0;
      height: 175px;
      width: 156px; }
      .question-3 .hex_colours_list .hex_colours_list_item:last-child {
        margin-right: 0; }
      .question-3 .hex_colours_list .hex_colours_list_item .hex_colours_list_image {
        height: 175px;
        width: 156px; } }
  @media (min-width: 1280px) {
    .question-3 .hex_colours_list .hex_colours_list_item {
      margin: 0 90px 20px 0; }
      .question-3 .hex_colours_list .hex_colours_list_item:last-child {
        margin-right: 0; } }

.filters_list {
  border-width: 1px 0 0;
  border-color: #DCDCDC;
  border-style: solid;
  overflow: hidden;
  max-height: 35px; }

.filters_list_active {
  -webkit-backface-visibility: hidden;
  -webkit-transition: max-height 750ms ease-in-out;
  transition: max-height 750ms ease-in-out;
  max-height: 100%; }
  .filters_list_active .listHeader {
    background: #8AD6B1; }

.filters_list_item {
  -webkit-backface-visibility: hidden;
  -webkit-transition: background-color 250ms ease-in-out;
  transition: background-color 250ms ease-in-out;
  padding: 7px 0 7px 20px; }
  .filters_list_item:not(.listHeader):hover {
    background: #F2F2F2;
    cursor: pointer; }

.listHeader {
  text-transform: uppercase;
  background: #F2F2F2; }
  .listHeader:hover {
    cursor: pointer; }

.talking__about__list {
  margin: 0 auto;
  width: 265px; }

.talking__about__list__item {
  vertical-align: middle;
  display: inline-block;
  margin: 5% 4.5%;
  width: 40%; }

.talking__about__list__item:last-child {
  margin-right: auto;
  margin-left: auto;
  display: block; }

.talking__about__list__item__image {
  width: 100%; }

@media (min-width: 600px) {
  .talking__about__list {
    display: inline-block;
    vertical-align: middle; }
  .talking__about__list {
    margin: 0 0 0 10px; } }

@media (min-width: 768px) {
  .talking__about__list {
    padding-top: 15px; } }

@media (min-width: 1024px) {
  .talking__about__list {
    text-align: left;
    padding-top: 40px;
    width: 560px; }
  .talking__about__list__item {
    margin: 0 10px;
    width: 89px; }
  .talking__about__list__item:last-child {
    display: inline-block;
    margin: 0 10px; } }

@media (min-width: 1280px) {
  .talking__about__list {
    width: 800px; }
  .talking__about__list__item {
    margin: 0 10px;
    width: 120px; } }

.product_detail .product_detail_title {
  color: #B3B3B3; }
  .product_detail .product_detail_title:not(:first-child) {
    margin-top: 5px; }

.product_detail .product_detail_description {
  margin: 0; }

.product_page_gallery .slick-dots {
  position: absolute;
  text-align: center;
  height: 20px;
  width: 100%;
  bottom: 0;
  left: 0; }
  .product_page_gallery .slick-dots li {
    display: inline-block;
    height: auto;
    margin: 5px;
    width: auto; }
    .product_page_gallery .slick-dots li.slick-active button {
      opacity: 1; }
    .product_page_gallery .slick-dots li button {
      text-indent: -9999px;
      border-radius: 50%;
      background: black;
      opacity: 0.5;
      height: 7px;
      width: 7px;
      padding: 0;
      margin: 0; }

.product_list {
  border-bottom: 1px solid #B3B3B3;
  border-top: 1px solid #B3B3B3;
  display: block; }

.product_wrapper {
  border-bottom: 1px solid #B3B3B3; }
  .product_wrapper:last-child {
    border: none; }

.product_details {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .product_details .product_image_holder {
    max-width: 60px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
    .product_details .product_image_holder .product_image {
      height: 60px;
      width: 60px; }
  .product_details .product_name {
    padding: 0 10px;
    -webkit-box-flex: 2;
        -ms-flex-positive: 2;
            flex-grow: 2; }
  .product_details .product_quantityAndPrice {
    text-align: right; }
  .product_details .quantityAndPrice {
    max-width: 100px;
    float: right; }
  .product_details .qty_change {
    display: inline-block;
    text-indent: -9999px;
    line-height: 0;
    height: 18px;
    width: 16px;
    margin: 0; }
    .product_details .qty_change.product_quantity_decrease {
      background: url("../../../assets/images/icons/decrease.svg") center center no-repeat; }
    .product_details .qty_change.product_quantity_increase {
      background: url("../../../assets/images/icons/increase.svg") center center no-repeat; }
  .product_details input {
    display: inline-block;
    text-align: center;
    padding: 0;
    width: 45%;
    border: 0; }
  .product_details .product_retail_price {
    clear: both; }

.product_remove {
  background: #F2F2F2;
  text-align: center;
  padding: 5px 0; }
  .product_remove .product_remove_action {
    background: url("../../../assets/images/icons/close-icon.svg") center right no-repeat;
    background-size: auto 100%;
    text-decoration: none;
    padding-right: 25px; }

.cart_totals {
  background: #F2F2F2;
  text-align: right; }
  .cart_totals > div {
    padding: 5px 15px; }
  .cart_totals .total {
    background: #000;
    color: #fff; }

@media (min-width: 768px) {
  .product_details .product_quantityAndPrice {
    max-width: none; }
  .product_details .quantityAndPrice {
    margin-right: 50px;
    max-width: 90px;
    float: none; }
  .product_details .quantityAndPrice,
  .product_details .product_retail_price {
    display: inline-block; } }

.card {
  vertical-align: top;
  margin: 0 auto 40px;
  width: 80%; }
  .card a {
    text-decoration: none;
    color: inherit; }

.card__header.text {
  vertical-align: middle; }
  .card__header.text p {
    line-height: 1.2;
    margin-bottom: 0; }

.card__header,
.card__footer {
  background-color: #fff;
  position: relative;
  text-align: center;
  margin: 0px auto;
  display: block;
  height: 40px;
  width: 100%; }

.card__header_image,
.card__footer_image {
  margin: -10px auto;
  height: 40px;
  width: auto; }

.card__footer_image {
  margin: 0 auto -40px; }

.card__image_wrapper {
  position: relative;
  line-height: 0;
  z-index: 0; }
  .card__image_wrapper:after {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfcfc+17,000000+98&0+25,0.65+99 */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(17%, rgba(252, 252, 252, 0)), color-stop(25%, rgba(227, 227, 227, 0)), color-stop(95%, rgba(0, 0, 0, 0.44)), color-stop(39%, rgba(0, 0, 0, 0.45)));
    background: linear-gradient(to bottom, rgba(252, 252, 252, 0) 17%, rgba(227, 227, 227, 0) 25%, rgba(0, 0, 0, 0.44) 95%, rgba(0, 0, 0, 0.45) 39%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcfcfc', endColorstr='#a6000000',GradientType=0 );
    /* IE6-9 */
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0; }

.card_title_wrapper {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0; }

.card__image {
  height: auto;
  width: 100%; }

.card__title {
  vertical-align: middle;
  text-align: right;
  line-height: 0.8;
  margin-bottom: 0;
  color: #fff;
  height: 42px; }

.card__title,
.card__image_hex {
  display: inline-block; }

.card__image_hex {
  vertical-align: text-bottom;
  margin: 0 10px; }

.card__form {
  margin: 0 auto;
  width: 90%; }

.card__description {
  background-color: #FEDE94;
  padding: 20px;
  height: 100%; }
  .card__description p {
    text-align: center; }
    .card__description p:last-child {
      margin: 0; }

.card:nth-child(3n+1) .card__description {
  background-color: rgba(254, 222, 148, 0.75); }

.card:nth-child(3n+2) .card__description {
  background-color: rgba(234, 151, 129, 0.75); }

.card:nth-child(3n+3) .card__description {
  background-color: rgba(138, 214, 177, 0.75); }

.mandatory_error {
  border: 1px solid #ff0000; }

.notice {
  border: 1px solid #fff;
  padding: 10px 15px;
  margin-top: 20px; }

.errormessage {
  background-color: #990000;
  color: #fff; }

.success {
  background-color: #00AA00;
  color: #fff; }

.mask_image_wrapper {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  height: 260px;
  z-index: 0; }

.image_mask {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0; }

.image_masked {
  vertical-align: top;
  position: relative;
  height: 260px;
  width: 260px;
  z-index: 0;
  z-index: 1; }

.accountSignIn {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 650ms ease-in-out;
  transition: all 650ms ease-in-out;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 10;
  left: 0;
  top: 0;
  min-height: 100%;
  overflow: hidden; }
  .accountSignIn .accountSignIn__content__wrapper {
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 950ms ease-in-out;
    transition: all 950ms ease-in-out;
    margin: 0 0 0 -150px;
    visibility: hidden;
    position: absolute;
    width: 300px;
    z-index: 100;
    opacity: 0;
    left: 50%;
    top: 0%; }
  .accountSignIn.showPopup {
    background: rgba(0, 0, 0, 0.45);
    visibility: visible;
    opacity: 1; }
    .accountSignIn.showPopup .accountSignIn__content__wrapper {
      visibility: visible;
      opacity: 1;
      top: 2.5%; }
  .accountSignIn .card {
    background: #fff;
    display: none;
    width: 100%; }
    .accountSignIn .card.shown {
      display: block; }
    .accountSignIn .card .card__title {
      line-height: inherit;
      margin-bottom: 20px;
      text-align: center;
      color: #000;
      height: auto; }
    .accountSignIn .card .card__description {
      background: url("../../../assets/images/mmhh-pattern-white-outline.png") #DCDCDC repeat;
      -webkit-overflow-scrolling: touch;
      text-align: center; }
      .accountSignIn .card .card__description.scrollyThis {
        overflow-x: hidden; }
    .accountSignIn .card form {
      margin: 0 auto; }
  .accountSignIn .formWrap {
    padding: 0 10px; }
  .accountSignIn form button {
    background-color: #BADBDB;
    color: #000; }
  .accountSignIn .close_popup {
    -webkit-text-decoration: center;
            text-decoration: center;
    display: inline-block;
    position: relative;
    width: 100%; }
    .accountSignIn .close_popup a {
      text-decoration: underline; }
  .accountSignIn .plus_icon {
    background: url("../../../assets/images/icons/increase.svg") no-repeat center center;
    display: inline-block;
    position: relative;
    margin: 0 0 -10px 0;
    height: 31px;
    width: 28px; }
    .accountSignIn .plus_icon.close {
      background: url("../../../assets/images/icons/close-icon.svg") no-repeat center center; }
  @media (min-width: 768px) {
    .accountSignIn .accountSignIn__content__wrapper {
      margin-left: -180px;
      width: 360px; }
      .accountSignIn .accountSignIn__content__wrapper .card .card__title {
        text-align: center;
        width: 100%; }
      .accountSignIn .accountSignIn__content__wrapper .card .card__description p:last-child {
        margin: 10px auto 0; } }

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  overflow-x: hidden;
  min-height: 100%; }

body {
  font-family: "Carnas","Tahoma", "Geneva";
  min-height: 100%;
  overflow-x: hidden;
  padding: 0;
  z-index: 0; }

.siteWrapper {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  will-change: left, right;
  position: relative;
  min-height: 100%;
  overflow: hidden;
  display: block;
  width: 100%;
  right: auto;
  left: auto; }

section {
  z-index: 1;
  padding: 0;
  margin: 0; }

.siteWrapper section:not(.sectionOne) {
  opacity: 0; }

.section__content a {
  color: inherit; }
  .section__content a:hover {
    text-decoration: underline; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  ul li {
    text-decoration: none;
    display: block;
    width: 100%; }

.clear {
  display: block;
  clear: both; }

.author {
  display: block; }

.hideOnLoad {
  display: none; }

.showPackage {
  display: block; }

.fullWidthHR {
  border: none;
  border-color: #DCDCDC;
  border-width: 1px 0 0 0;
  border-style: solid;
  margin: 10px -10px; }

#mce-responses .response {
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  color: #990000; }

.header .header__top_bar {
  background: url("../../../assets/images/icons/makemyhousehomeLogo.svg") rgba(255, 255, 255, 0.8) no-repeat center center/50px 55px;
  text-transform: uppercase;
  position: absolute;
  height: 70px;
  width: 100%;
  z-index: 3;
  left: 0;
  top: 0; }
  @media (min-width: 1024px) {
    .header .header__top_bar {
      height: 100px; } }

.header .header__name, .header .header__logo, .header .header__nav {
  display: inline-block; }

.header .header__name {
  vertical-align: top;
  padding: 0 0 0 5px;
  margin: 28px 0 0;
  font-size: 11px; }
  .header .header__name b {
    font-weight: bold; }
  .header .header__name a {
    text-decoration: none;
    color: inherit; }
  @media (min-width: 600px) {
    .header .header__name {
      margin: 21px 0 0 10px;
      font-size: 20px; } }
  @media (min-width: 768px) {
    .header .header__name {
      margin: 18px 0 0 10px;
      font-size: 25px; } }
  @media (min-width: 1024px) {
    .header .header__name {
      margin: 27px 0 0 10px;
      font-size: 35px; } }

.header .header__nav {
  position: relative;
  float: right; }
  @media (min-width: 1024px) {
    .header .header__nav {
      height: 100px; } }

.header .header__nav__toggle {
  text-align: center;
  font-size: 13px;
  height: 70px;
  width: 68px;
  padding: 0;
  margin: 0; }
  .header .header__nav__toggle a {
    background: url("../../../assets/images/icons/makemyhousehome.png") no-repeat center 15px/20px auto;
    text-decoration: none;
    padding-top: 42px;
    display: block;
    color: #000;
    height: 20px;
    width: 100%; }
  @media (min-width: 1024px) {
    .header .header__nav__toggle {
      display: none; } }

.header .header__nav__list {
  background: rgba(255, 255, 255, 0.8);
  list-style: none;
  display: none; }
  .header .header__nav__list.open {
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    display: block;
    width: 100vw;
    top: 100%;
    right: 0; }
  .header .header__nav__list .nav__list__item a {
    -webkit-backface-visibility: hidden;
    -webkit-transition: background-color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out;
    background-image: url("../../../assets/images/icons/menu-unvisited-icon.svg");
    background-position: 20px center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 25px auto;
    padding: 20px 0 20px 55px;
    text-decoration: none;
    display: block;
    color: #000; }
  .header .header__nav__list .nav__list__item:hover a,
  .header .header__nav__list .nav__list__item.current a {
    background-position: 20px 28px;
    background-size: 25px auto; }
  .header .header__nav__list .nav__list__item br {
    display: none; }
  .header .header__nav__list .nav__list__item.menu__header__account {
    display: inline-block;
    text-align: center;
    clear: none;
    width: auto; }
    .header .header__nav__list .nav__list__item.menu__header__account .showAccountSignIn {
      padding: 10px 0 20px 20px;
      background-image: none; }
      .header .header__nav__list .nav__list__item.menu__header__account .showAccountSignIn:hover {
        text-decoration: underline;
        background: none; }
  .header .header__nav__list .nav__list__item:nth-child(5n+1):hover a {
    background-image: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #fff;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  .header .header__nav__list .nav__list__item:nth-child(5n+1).current a {
    background: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #fff;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  @media (min-width: 1024px) {
    .header .header__nav__list .nav__list__item:nth-child(5n+1):hover a,
    .header .header__nav__list .nav__list__item:nth-child(5n+1).current a {
      background-position: center 18px;
      background-size: 25px auto; } }
  .header .header__nav__list .nav__list__item:nth-child(5n+2):hover a {
    background-image: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #FEDE94;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  .header .header__nav__list .nav__list__item:nth-child(5n+2).current a {
    background: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #FEDE94;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  @media (min-width: 1024px) {
    .header .header__nav__list .nav__list__item:nth-child(5n+2):hover a,
    .header .header__nav__list .nav__list__item:nth-child(5n+2).current a {
      background-position: center 18px;
      background-size: 25px auto; } }
  .header .header__nav__list .nav__list__item:nth-child(5n+3):hover a {
    background-image: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #EA9781;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  .header .header__nav__list .nav__list__item:nth-child(5n+3).current a {
    background: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #EA9781;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  @media (min-width: 1024px) {
    .header .header__nav__list .nav__list__item:nth-child(5n+3):hover a,
    .header .header__nav__list .nav__list__item:nth-child(5n+3).current a {
      background-position: center 18px;
      background-size: 25px auto; } }
  .header .header__nav__list .nav__list__item:nth-child(5n+4):hover a {
    background-image: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #8AD6B1;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  .header .header__nav__list .nav__list__item:nth-child(5n+4).current a {
    background: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #8AD6B1;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  @media (min-width: 1024px) {
    .header .header__nav__list .nav__list__item:nth-child(5n+4):hover a,
    .header .header__nav__list .nav__list__item:nth-child(5n+4).current a {
      background-position: center 18px;
      background-size: 25px auto; } }
  .header .header__nav__list .nav__list__item:nth-child(5n+5):hover a {
    background-image: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #84D8FF;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  .header .header__nav__list .nav__list__item:nth-child(5n+5).current a {
    background: url("../../../assets/images/icons/makemyhousehomeLogo.svg");
    background-color: #84D8FF;
    background-position: 20px center;
    background-repeat: no-repeat;
    background-size: 25px auto; }
  @media (min-width: 1024px) {
    .header .header__nav__list .nav__list__item:nth-child(5n+5):hover a,
    .header .header__nav__list .nav__list__item:nth-child(5n+5).current a {
      background-position: center 18px;
      background-size: 25px auto; } }
  @media (min-width: 1024px) {
    .header .header__nav__list {
      background: transparent;
      display: block;
      height: 100%; }
      .header .header__nav__list.open {
        position: relative;
        width: auto;
        top: 0; }
      .header .header__nav__list .nav__list__item {
        display: inline-block;
        vertical-align: top;
        padding-left: 0;
        height: 100%;
        width: 75px; }
        .header .header__nav__list .nav__list__item a {
          background-position: center 20px;
          background-size: 20px auto;
          word-wrap: break-word;
          text-align: center;
          padding: 55px 0 0;
          font-size: 10px;
          height: 100px;
          width: 100%; }
        .header .header__nav__list .nav__list__item br {
          display: inline-block; }
        .header .header__nav__list .nav__list__item.menu__header__account {
          display: none; } }
  @media (min-width: 1280px) {
    .header .header__nav__list .nav__list__item {
      width: 100px; }
      .header .header__nav__list .nav__list__item a {
        font-size: 13px; } }

.header .header__account {
  background: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  position: absolute;
  text-align: right;
  padding: 5px 10px;
  display: none;
  width: 100%;
  clear: both;
  top: 70px;
  z-index: 2;
  right: 0; }
  .header .header__account p {
    margin-bottom: 0; }
  .header .header__account a {
    text-decoration: none;
    color: #000; }
    .header .header__account a:hover {
      text-decoration: underline; }
  @media (min-width: 1024px) {
    .header .header__account {
      display: block;
      top: 100px; } }

.footer__pattern {
  background: url("../../../assets/images/mmhh-pattern-white-outline.png") #DCDCDC;
  min-height: 75px; }

.subscription__form__wrapper {
  vertical-align: top;
  max-width: 500px;
  margin: 0 auto; }
  .subscription__form__wrapper::after {
    display: block;
    content: ' ';
    clear: both; }
  @media (min-width: 768px) {
    .subscription__form__wrapper {
      display: inline-block;
      margin-left: 40px; } }
  @media (min-width: 1280px) {
    .subscription__form__wrapper {
      margin-left: 60px; } }

.instagramFeed {
  position: relative;
  padding: 0 0 20px;
  margin: 0; }
  .instagramFeed .section__content::after {
    background-color: rgba(255, 255, 255, 0); }
  .instagramFeed .section__title_static {
    padding: 10px 0 15px;
    position: relative;
    text-align: center;
    margin: 0; }
    .instagramFeed .section__title_static a {
      text-decoration: none; }
  .instagramFeed #instafeed a {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    vertical-align: top;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 33.33%; }
    .instagramFeed #instafeed a img {
      height: auto;
      width: 100%; }
  @media (min-width: 768px) {
    .instagramFeed .section__title_static br {
      display: none; }
    .instagramFeed #instafeed a {
      width: 16.666%; } }
  @media (min-width: 1024px) {
    .instagramFeed .section__title_static {
      padding: 20px 0 25px; }
    .instagramFeed .section__title_image {
      margin-bottom: -18px;
      width: 50px; } }

.subscription {
  background-color: #BADBDB;
  padding: 30px 20px; }
  @media (min-width: 1024px) {
    .subscription {
      padding-top: 40px; } }
  @media (min-width: 1280px) {
    .subscription {
      padding-left: 60px; } }
  .subscription .subscription__title {
    margin: 0 auto 0.25em; }
  .subscription button {
    max-width: none; }
  @media (min-width: 768px) {
    .subscription .subscription__form input, .subscription .subscription__form button {
      display: inline-block;
      float: left; }
    .subscription .subscription__form input {
      border-radius: 1px 0 0 1px;
      width: 390px; }
    .subscription .subscription__form button {
      border-radius: 0 1px 1px 0;
      width: 110px; } }

.footer__contact-details {
  background-color: #F2F2F2;
  padding: 25px 0; }
  .footer__contact-details .footer__logo {
    margin: 0 auto 30px;
    display: block; }
    @media (min-width: 768px) {
      .footer__contact-details .footer__logo {
        position: absolute;
        margin-left: -64px;
        left: 50%; } }

.contactDetails {
  list-style: none;
  padding: 0 10px;
  margin: 0; }
  .contactDetails p {
    vertical-align: text-bottom;
    display: inline-block;
    margin: 0 0 12px 5px; }
  .contactDetails a {
    text-decoration: none;
    color: #000; }
  @media (min-width: 768px) {
    .contactDetails {
      float: left; } }

.contactDetails .phone {
  padding-left: 15px;
  margin: 0px 0 -7px; }

.contactDetails .faq {
  padding-left: 15px;
  margin: -8px 0 0px; }

.contactDetails .wrapSVG {
  margin: 0; }

.socialIcons {
  position: relative;
  margin-top: 10px;
  padding: 0 20px; }
  .socialIcons::after {
    position: relative;
    display: block;
    content: ' ';
    clear: both;
    width: 100%; }
  .socialIcons .socialIcons__item {
    vertical-align: top;
    margin-right: 5px;
    float: right;
    width: auto; }
  .socialIcons .socialIcons__item.tumblr {
    margin: 0 5px -8px 0;
    display: block; }
  .socialIcons .socialIcons__item.twitter {
    margin: 0 19px -8px 0;
    display: block; }
  .socialIcons .socialIcons__item.instagram {
    margin-right: 2px;
    clear: both; }
  .socialIcons .socialIcons__item.linkedin {
    margin: -8px 20px -8px 0;
    clear: right; }
  .socialIcons .socialIcons__item.pinterest {
    margin: -8px 5px -8px 0; }
  @media (min-width: 768px) {
    .socialIcons {
      margin-top: 0;
      float: right; } }

.whoDidWhat {
  text-align: center;
  background: #000;
  padding: 20px 5px;
  display: block;
  color: #fff;
  clear: both; }
  .whoDidWhat p {
    font-size: 13px; }
  .whoDidWhat small {
    font-size: 10px; }
    .whoDidWhat small a {
      color: #fff; }
  @media (min-width: 768px) {
    .whoDidWhat {
      padding: 20px 20px 10px; }
      .whoDidWhat p {
        float: left; }
      .whoDidWhat small {
        float: right; } }
